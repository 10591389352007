import {useEffect, useState} from 'react';
import {
  groupByGuestTableState,
  groupsToTableState,
  promoCodesToTableState,
  toCreateCodeData,
  toSelectOptions,
  toSellersOptions,
  toUpdateCodeData,
  validateEmail,
} from '../helpers/codes';
import {
  createCodeGroup,
  deleteCodeGroup,
  deleteGuest,
  deletePromoCode,
  getBrands,
  getCodeGroup,
  getCodeGroupGuests,
  getEventTemplates,
  getListCodeGroups,
  getListPromoCodes,
  getSellers,
  resetGroup,
  sendGuestEmails,
  sendPromoCode,
  shareCodeGroup,
  sharePromoCode,
  updateCodeGroup,
  updatePromoCode,
} from '../queries/codes';
import {
  CodeGroupT,
  getCodeGroupRes,
  getListCodeGroupsRes,
  MetricsT,
  PromoCodeT,
  updateCodeGroupRes,
} from '../queries/types/codes';
import {SelectOptionT} from '../types/helpers';
import {useGetDepartments} from './settings';
import {
  CodeCreateFields,
  CodeAccessTypes,
  GroupDetailTableDataT,
  OnUpdateCodeT,
  GuestT,
  CodeGroupGuestT,
  CodeViewFields,
  CodeViewStateT,
  CodeViewStateChange,
  GenerateCSVResT,
} from '../types/codes';
import {useGetAssociates} from './auth';
import {useRecoilState} from 'recoil';
import {creatingGroup} from '../states/group';
import {message, notification} from 'antd';
import {getErrorResponse} from '../helpers/helpers';
import {format} from 'date-fns';
import {getBrandTheme} from './brand';
import {themes} from '../ui-kit/theme/theme';

export type useCreateCodesT = {
  onCreate?: (values: any) => Promise<boolean | undefined>;
  error?: string;
  success?: {
    status?: 'completed' | 'failed';
    total?: number;
  };
  id?: string;
  options: {
    departments: SelectOptionT[];
    associates: SelectOptionT[];
    sellers: SelectOptionT[];
    brand: SelectOptionT[];
    tickets: {label: string; value: string}[];
  };
  createLoading: boolean;
  refetchSellers: (brand?: string) => Promise<void>;
};

export const useCreateCodes = (onSuccess?: () => void): useCreateCodesT => {
  const [error, setError] = useState('');
  const [createLoading, setCreateLoading] = useState(false);
  const [success, setSuccess] = useState<useCreateCodesT['success']>({});
  const [id, setId] = useState('');
  const {departments} = useGetDepartments();
  const {associatesOptions} = useGetAssociates();
  const {sellers, refetchSellers} = useGetSellers();
  const {brandOptions} = useGetBrands();
  const {ticketTypeOptions} = useGetEventTemplates();
  const onCreate = async (values: any) => {
    setCreateLoading(true);
    try {
      const body = toCreateCodeData(values);
      console.log('body:', body);
      // setCreated(toCreatingState(body));
      const res = await createCodeGroup({body});
      if (res) {
        setCreateLoading(false);
        // setCreated({isCreated: true});
        setSuccess({status: 'completed', total: res.body?.total});
        setId(String(res?.body?.id));
        onSuccess?.();
        setError('');
        return true;
      }
    } catch (e) {
      setCreateLoading(false);
      setError('Create Error');
      console.log('Error:', e);
      const jsonErr = JSON.parse(JSON.stringify(e))?.response?.body?.message || 'Creation Error';
      openNotifError(jsonErr);
      return false;
    }
  };

  return {
    onCreate,
    error,
    success,
    id,
    createLoading,
    options: {
      departments: toSelectOptions(departments),
      associates: associatesOptions,
      sellers: toSellersOptions(sellers),
      brand: brandOptions || [],
      tickets: ticketTypeOptions || [],
    },
    refetchSellers,
  };
};

export const useEditCodes = (groupId?: string, onSuccess?: () => void): useCreateCodesT => {
  const [error, setError] = useState('');
  const [createLoading, setCreateLoading] = useState(false);
  const [success, setSuccess] = useState<useCreateCodesT['success']>({});
  const {departments} = useGetDepartments();
  const {associatesOptions} = useGetAssociates();
  const {sellers, refetchSellers} = useGetSellers();
  const {brandOptions} = useGetBrands();
  const {ticketTypeOptions} = useGetEventTemplates();
  const onUpdate = async (values: any) => {
    try {
      if (!groupId) return false;
      setCreateLoading(true);
      const body = toUpdateCodeData(values);
      const res = await updateCodeGroup({groupId, body});
      const resBody = res.body as updateCodeGroupRes;

      if (resBody.id) {
        setCreateLoading(false);
        setSuccess({status: 'completed'});
        onSuccess?.();
        setError('');
        return true;
      }
    } catch (e) {
      setCreateLoading(false);
      setError('Update Error');
      setSuccess({});
      console.log('Error:', e);
      return false;
    }
  };

  return {
    onCreate: onUpdate,
    error,
    success,
    options: {
      departments: toSelectOptions(departments),
      associates: associatesOptions,
      sellers: toSellersOptions(sellers),
      brand: brandOptions || [],
      tickets: ticketTypeOptions || [],
    },
    createLoading,
    refetchSellers,
  };
};

export const useDeleteCodesGroup = (onSuccess?: () => void) => {
  const onDelete = async (groupId: string) => {
    try {
      const res = await deleteCodeGroup({groupId});
      if (res) onSuccess?.();
    } catch (e) {}
  };
  return {onDelete};
};

export const useGetCodesTable = () => {
  //pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [groupLimit, setGroupLimit] = useState(10);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);
  const [metricsLoading, setMetricsLoading] = useState(false);
  const [codes, setCodes] = useState<getListCodeGroupsRes>([]);
  const [nonFilteredCodes, setNonFilteredCodes] = useState<getListCodeGroupsRes>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [fullList, setFullList] = useState<getListCodeGroupsRes>([]);
  const [currentTab, setCurrentTab] = useState<string>('all');
  const [metrics, setMetrics] = useState<MetricsT>({
    codesUploaded: 0,
    codesSent: 0,
    codesUsed: 0,
  });
  const [creatingGroupData] = useRecoilState(creatingGroup);

  const fetch = () => {
    setLoading(true);
    const brand = currentTab === 'all' ? '' : currentTab;
    getListCodeGroups({pagination: true, page: pageNumber, limit: groupLimit, brand}).then((res) => {
      const results = res?.body?.results;
      setHasNextPage(res?.body?.next);
      setTotal(res?.body?.total);
      setCodes(results as getListCodeGroupsRes);
      setNonFilteredCodes(results as getListCodeGroupsRes);
      setLoading(false);
    });
  };

  const fetchMetrics = async () => {
    setMetricsLoading(true);
    const res = await getListCodeGroups({pagination: false});
    setFullList(res?.body);
    setMetrics(
      res?.body.reduce(
        (acc: MetricsT, el: CodeGroupT) => {
          acc.codesUploaded += el.codesUploaded || 0;
          acc.codesSent += el.codesSent || 0;
          acc.codesUsed += el.codesUsed || 0;
          return acc;
        },
        {
          totalGroups: res?.body?.length || 0,
          codesUploaded: 0,
          codesSent: 0,
          codesUsed: 0,
        },
      ),
    );
    setMetricsLoading(false);
  };

  const searchCodes = (v: string) => {
    if (fullList) {
      if (v === '') {
        setIsSearching(false);
        setCodes(nonFilteredCodes);
        return;
      }
      setIsSearching(true);
      if (fullList.some((el) => el.clientName.toLowerCase().includes(v.toLowerCase()))) {
        setCodes(fullList.filter((el) => el.clientName.toLowerCase().includes(v.toLowerCase())));
      } else if (fullList.some((el) => el.contactPerson.toLowerCase().includes(v.toLowerCase()))) {
        setCodes(fullList.filter((el) => el.contactPerson.toLowerCase().includes(v.toLowerCase())));
      } else if (fullList.some((el) => el.brand?.toLowerCase() === v.toLowerCase())) {
        setCodes(fullList.filter((el) => el.brand?.toLowerCase() === v.toLowerCase()));
      } else {
        setCodes([]);
      }
    }
  };

  const changeTab = (val: string) => {
    setCurrentTab(val);
    setPageNumber(1);

    if (val === 'all') {
      setTotal(fullList?.length);
      setCodes(nonFilteredCodes);
      return;
    }
    const codes = fullList.filter((el) => el.brand === val);
    const total = codes.length;
    setTotal(total);
    return setCodes(codes?.slice(0, groupLimit));
  };
  const changePage = (val: number) => setPageNumber(val);
  const changeLimit = (_: number, val: number) => setGroupLimit(val);

  useEffect(() => {
    fetch();
  }, [pageNumber, groupLimit]);

  useEffect(() => {
    fetchMetrics();
  }, []);
  return {
    codes: groupsToTableState(codes, creatingGroupData),
    metrics,
    refetch: fetch,
    loading,
    metricsLoading,
    searchCodes,
    currentTab,
    changeTab,
    pagination: {
      hasNextPage,
      pageNumber,
      groupLimit,
      changePage,
      changeLimit,
      total,
    },
    isSearching,
  };
};

export const useGetCodeGroup = (id?: string, skip?: boolean) => {
  const [code, setCode] = useState<getCodeGroupRes>();
  const [loading, setLoading] = useState(false);
  const fetch = () => {
    if (!id || skip) return;
    setLoading(true);
    getCodeGroup({groupId: id}).then((res) => {
      setCode(res?.body as getCodeGroupRes);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetch();
  }, [id, skip]);

  return {code, loading, refetch: fetch};
};

export enum TableViews {
  codes = 'codes',
  guests = 'guests',
}

export const useGetListPromoCodes = (id?: string) => {
  const [tableView, setTableView] = useState<TableViews>(TableViews.guests);
  const [guestData, setGuestData] = useState<GuestT>();
  const [promoCodes, setPromoCodes] = useState<PromoCodeT[]>();
  const [initialPromocodes, setInitialPromocodes] = useState<PromoCodeT[]>();
  const [guests, setGuests] = useState<CodeGroupGuestT[]>();
  const [loading, setLoading] = useState(false);
  const [notSentEmails, setNotSentEmails] = useState('');
  const {fetchGuest} = useGetGuest(id);
  const onSetTableView = (view: TableViews) => setTableView(view);
  const fetch = async () => {
    if (!id) return;
    setLoading(true);
    const guest = await fetchGuest();
    const codes = await getListPromoCodes({groupId: id});
    setPromoCodes(codes?.body);
    setInitialPromocodes(codes?.body);
    setGuestData(guest);
    setGuests(guest?.codeGroupGuests);
    setNotSentEmails(
      codes?.body.filter((el: any) => el?.outboundStatus === CodeAccessTypes.notsent && el.email).length,
    );
    setLoading(false);
  };

  const searchPromoCodes = (v: string) => {
    if (guests) {
      if (v === '') {
        setGuests(guestData?.codeGroupGuests);
        setPromoCodes(initialPromocodes);
        return;
      }

      const searchValues = v.split(',').map((el) => el.toLowerCase().trim());
      const results: CodeGroupGuestT[] = [];

      guestData?.codeGroupGuests.forEach((guest) => {
        const guestName = guest?.guestName?.toLowerCase();
        const guestEmail = guest?.guestEmail?.toLowerCase();

        const searched = searchValues.some((sv) => guestName.includes(sv) || guestEmail.includes(sv));
        if (searched) results.push(guest);
      });
      setGuests(results);

      // if (promoCodes?.some((el) => el.guestName?.toLowerCase().includes(value))) {
      //   setPromoCodes(promoCodes?.filter((el) => el.guestName?.toLowerCase()?.includes(value)));
      // } else if (promoCodes?.some((el) => el.email?.toLowerCase().includes(value))) {
      //   setPromoCodes(promoCodes?.filter((el) => el.email?.toLowerCase().includes(value)));
      // } else {
      //   setPromoCodes([]);
      // }
    }
  };

  useEffect(() => {
    fetch();
  }, [id]);
  const table =
    tableView === TableViews.guests
      ? guests && groupByGuestTableState(guests, promoCodes)
      : promoCodes && promoCodesToTableState(promoCodes);
  const {downloadCSV} = useCreateCodesCSV(promoCodes);

  return {
    promoCodes,
    notSentEmails,
    tableData: table?.table,
    loading,
    usedCodes: table?.used,
    refetch: fetch,
    searchPromoCodes,
    tableView,
    onSetTableView,
    downloadCSV: tableView === TableViews.codes ? downloadCSV : undefined,
  };
};

export const useSendCode = (groupId?: string, onSuccess?: () => void, r?: boolean) => {
  const sendCode = async (codeId?: string) => {
    if (!groupId || !codeId) return false;
    try {
      const res = await sendPromoCode({groupId, codeId});
      if (res?.ok && r) onSuccess?.();
      return true;
    } catch (e) {
      return false;
    }
  };
  return {sendCode};
};

export const useSendGuestEmail = (groupId?: string, onSuccess?: () => void, r?: boolean) => {
  const sendGuest = async (guestIds?: string[]) => {
    if (!groupId || !guestIds?.length) return false;
    try {
      const body = {guestIds: guestIds?.map((el) => Number(el))};
      const res = await sendGuestEmails({groupId, body});
      if (res?.ok && r) onSuccess?.();
      return true;
    } catch (e) {
      return false;
    }
  };
  return {sendGuest};
};

export const openNotifError = (message?: string) => {
  notification['error']({
    message: 'Error',
    description: message,
    duration: 6,
  });
};

export type usePromoCodesActionsT = {
  onDelete?: (codeId?: string) => Promise<boolean>;
  onUpdate?: (props: {codeId?: string; email?: string; guestName?: string}) => Promise<boolean>;
};

export const usePromoCodesActions = (groupId?: string, onSuccess?: () => void) => {
  const onDelete = async (codeId?: string) => {
    if (!codeId || !groupId) return false;
    try {
      const res = await deleteGuest({groupId, guestId: codeId});
      if (res) onSuccess?.();
      return true;
    } catch (e) {
      return false;
    }
  };

  const onDeletePromoCode = async (codeId?: string) => {
    if (!codeId || !groupId) return false;
    try {
      const res = await deletePromoCode({groupId, codeId});
      if (res) onSuccess?.();
      return true;
    } catch (e) {
      return false;
    }
  };
  const onUpdate = async ({codeId, email, guestName}: {codeId?: string; email?: string; guestName?: string}) => {
    if (!codeId || !groupId || !email) return false;
    try {
      const res = await updatePromoCode({groupId, codeId, body: {email, guestName}});
      if (res) onSuccess?.();
      return true;
    } catch (e) {
      console.log(e);
      const jsonErr = JSON.parse(JSON.stringify(e));
      openNotifError(jsonErr?.message || getErrorResponse(e));
      return false;
    }
  };
  return {onDelete, onDeletePromoCode, onUpdate};
};

export const useShareCodes = (groupId?: string, onSuccess?: () => void) => {
  const shareCode = async () => {
    if (!groupId) {
      openNotifError('wrong group id!');
      return false;
    }
    try {
      const res = await shareCodeGroup({groupId});
      if (res) onSuccess?.();
      return true;
    } catch (e) {
      console.log('e', e);
      const jsonErr = JSON.parse(JSON.stringify(e))?.response?.body?.message || 'Share Codes Error';
      openNotifError(jsonErr);
      return false;
    }
  };
  return {shareCode};
};

export const useAddCodes = (groupId?: string, onSuccess?: () => void) => {
  const addCodes = async (file: any[]) => {
    if (!groupId) return false;
    try {
      const bodyWithFile = {[CodeCreateFields.promoCodes]: file?.[0]};
      const body = toUpdateCodeData(bodyWithFile);
      const res = await updateCodeGroup({groupId, body});
      if (res) {
        onSuccess?.();
        return true;
      }
    } catch (e) {
      console.log(e);
      const jsonErr = JSON.parse(JSON.stringify(e))?.response?.body?.message;
      openNotifError(jsonErr || getErrorResponse(e));
      return false;
    }
  };
  return {addCodes};
};

export const useGetSellers = () => {
  const [sellers, setSellers] = useState();

  const fetch = async (brand?: string) => {
    try {
      const res = await getSellers(brand);
      setSellers(res?.body);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  return {sellers, refetchSellers: fetch};
};

export const useGetBrands = () => {
  const [brands, setBrands] = useState<{key: string; name: string}[]>();

  const fetch = async () => {
    try {
      const res = await getBrands();
      const theme = getBrandTheme();

      const list = res?.body?.filter((el: any) => {
        if (theme === themes.lite) return el?.name?.toLowerCase().includes('jingle');
        return !el?.name?.toLowerCase().includes('jingle');
      });
      setBrands(list);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  const brandOptions = brands?.map((el) => ({label: el.name, value: el.key}));
  return {brands, brandOptions};
};

export const useGetEventTemplates = () => {
  const [eventTemplates, setEventTemplates] = useState<string[]>();

  const fetch = async () => {
    try {
      const res = await getEventTemplates();
      setEventTemplates(res?.body);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  const ticketTypeOptions = eventTemplates?.map((el) => ({label: el, value: el}));
  return {eventTemplates, ticketTypeOptions};
};

export type codeState = {
  name?: string;
  email?: string;
  valid: boolean;
};
export type codeFieldsT = 'email' | 'name' | string;
export type codeStateVal = {key: codeFieldsT; value?: string | number};

export const useEditingCodes = (
  initialState?: GroupDetailTableDataT,
  onUpdate?: OnUpdateCodeT,
  editingId?: string,
  onSuccess?: (id?: string) => void,
) => {
  const [eCodeState, setEditingState] = useState<codeState>({valid: true});
  const handleEdit = (val: codeStateVal) => setEditingState((prev: any) => ({...prev, [val.key]: val?.value}));

  useEffect(() => {
    setEditingState((prev: codeState) => ({
      ...prev,
      name: initialState?.guestName?.guestName || '',
      email: initialState?.email?.email || '',
    }));
  }, [initialState?.id]);

  useEffect(() => {
    const isValid = !!validateEmail(eCodeState?.email) && (eCodeState.name?.length || 0) > 0;
    setEditingState((prev: any) => ({...prev, valid: isValid}));
  }, [eCodeState.name, eCodeState.email]);

  const onSave = async () => {
    await onUpdate?.({codeId: editingId, email: eCodeState.email, guestName: eCodeState.name});
    onSuccess?.();
    setEditingState({valid: true});
  };

  return {eCodeState, handleEdit, onSave};
};

export type useGuestChangeCodesT = {
  changeCodes: (id?: string) => ((value: string | number) => void) | undefined;
  codes: Record<string, number> | undefined;
};
export const useGuestChangeCodes = (data?: GroupDetailTableDataT[]): useGuestChangeCodesT => {
  const [codes, setCodes] = useState<Record<string, number>>();
  useEffect(() => {
    // initial state
    const Map: Record<string, number> = {};
    data?.forEach((el) => {
      if (!el?.id) return;
      Map[el.id] = Number(el?.code?.used?.length || 0);
    });
    setCodes(Map);
  }, [data?.length]);
  const changeCodes = (id?: string) => (number: number | string) => {
    if (id !== undefined) setCodes((prev) => ({...prev, [id]: Number(number) || 0}));
  };
  return {changeCodes, codes};
};

export const useGetGuest = (groupId?: string) => {
  const fetch = async () => {
    if (!groupId) return;
    const res = await getCodeGroupGuests({groupId});
    return res?.body as GuestT;
  };
  useEffect(() => {
    fetch();
  }, []);

  return {fetchGuest: fetch};
};

export type PromoStateT = Record<string, CodeViewStateT>;

export const useCodesView = (
  tableView: TableViews,
  promocodes?: GroupDetailTableDataT[],
  editingId?: string,
  onUpdate?: usePromoCodesActionsT['onUpdate'],
  setEditingId?: (id?: string) => void,
) => {
  const [promoState, setPromoState] = useState<PromoStateT>();
  const changePromoState: CodeViewStateChange = ({id, value, key}) => {
    let valid = true;
    if (key === CodeViewFields.name && (value?.length || 0) < 2) valid = false;
    if (key === CodeViewFields.email && !validateEmail(value)) valid = false;
    setPromoState((prev) => ({...prev, [id]: {...prev?.[id], [key]: value, valid}}));
  };

  const onUpdatePromo = async (id?: string) => {
    if (!id) return false;
    try {
      const name = promoState?.[id]?.name;
      const email = promoState?.[id]?.email;
      await onUpdate?.({guestName: name, email, codeId: id});
      setEditingId?.('');
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    if (tableView === TableViews.codes && editingId) {
      const item = promocodes?.find((el) => el?.id === editingId);
      const name = item?.guestName.guestName || '';
      const email = item?.email.email || '';
      setPromoState((prev) => ({...prev, [editingId]: {name, email, valid: true}}));
    }
  }, [tableView, editingId]);
  return {promoState, onUpdatePromo, changePromoState};
};

const CSV_HEADERS = ['Promo Code', 'Name', 'Email', 'Status', 'Redeemed At'];
export const useCreateCodesCSV = (codes?: PromoCodeT[]) => {
  const downloadCSV = (): GenerateCSVResT => {
    const data =
      codes?.map((el) => [
        el.code,
        el.guestName,
        el.email,
        el.outboundStatus,
        el.redeemedAt ? format(new Date(el.redeemedAt), 'Pp') : '',
      ]) || [];
    return {headers: CSV_HEADERS, data};
  };
  return {downloadCSV};
};

export const useShareCode = (groupId?: string | number, onSuccess?: () => void) => {
  const [shareLoading, setShareLoading] = useState('');
  const toggleShare = async (id?: string, unshare?: boolean) => {
    if (!id || groupId === undefined) return;
    try {
      setShareLoading((prev) => `${prev}[${id}]`);
      await sharePromoCode({groupId: String(groupId), codeId: id, unshare: unshare || false});
      onSuccess?.();
    } catch (error) {
      message.error({content: 'Share Code error'});
    } finally {
      setShareLoading((prev) => prev.replace(`[${id}]`, ''));
    }
  };
  return {toggleShare, shareLoading};
};

export const useResetGroup = (group?: CodeGroupT, onSuccess?: () => void) => {
  const groupId = group?.id;
  const onReset = async () => {
    if (!groupId && groupId !== 0) return false;
    try {
      const res = await resetGroup({groupId: String(groupId)});
      if (res) onSuccess?.();
      return true;
    } catch (e) {
      return false;
    }
  };
  return onReset;
};

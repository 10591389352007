import React from 'react';
import {WithChildren} from '../../types/helpers';
import {AppLayout} from '../../ui-kit/AppLayout';
import {Header} from '../../ui-kit/AppLayout/Header';
import {EmptyHeader} from '../../ui-kit/AppLayout/EmptyHeader';
import {ItemType} from 'antd/es/menu/hooks/useItems';
import {useLocation, useNavigate} from 'react-router-dom';
import {route, routePaths} from '../../constants/routes';
import {useLogOut, useViewer} from '../../hooks/auth';
import {getHeaderLinks} from '../../hooks/common';
import Footer from './Footer';
import {getPermissions} from '../../helpers/roles';
import {BrandSwitch} from '../Brand/BrandSwitch';
import {getBrandTheme} from '../../hooks/brand';

export const Layout: React.FC<WithChildren> = ({children}) => {
  const user = useViewer();
  const permissions = getPermissions();
  const onLogout = useLogOut();
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const onSettingsClick = permissions.haveSettings ? () => navigate(route.settings.path) : undefined;

  const rightText = `Hello, ${user?.firstName || user?.email}`;
  const leftText = 'PROMO CODE PORTAL';
  const ddLinks: ItemType[] = getHeaderLinks(permissions).map((el) => ({
    key: el.key,
    label: <a href={el.href}>{el.label}</a>,
  }));
  const logoTheme = getBrandTheme();

  const hideFooter = pathname.includes(routePaths.viewCodeGroup);
  const footer = hideFooter ? null : <Footer />;
  const header = user?.id ? (
    <Header
      onSettingsClick={onSettingsClick}
      onLogout={onLogout}
      dropDownLinks={ddLinks}
      rightText={rightText}
      leftText={leftText}
      logoTheme={logoTheme}
    />
  ) : (
    <EmptyHeader />
  );
  return (
    <AppLayout footer={footer} header={header}>
      <BrandSwitch />
      {children}
    </AppLayout>
  );
};

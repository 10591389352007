import React from 'react';
import {
  CreateButton,
  DashboardActions,
  DashboardCard,
  DashboardCardsWrapper,
  DashboardHeaderWrapper,
  PageWrapper,
  PaginationWrapper,
  StyledTabs,
} from './styles';
import {DashboardCardT, DashboardTableDataT, PaginationObjT} from '../../types/dashboard';
import {route} from '../../constants/routes';
import {DashboardTable} from './Table';
import {MetricsT} from '../../queries/types/codes';
import {Pagination, Spin, Tabs} from 'antd';
import {brands, getBrandFromCookie} from '../../hooks/brand';

type DashboardPageProps = {
  codes?: DashboardTableDataT[];
  goEdit: (id: string) => void;
  onDelete: (id: string) => void;
  loading?: boolean;
  metrics: MetricsT;
  canAddEdit: boolean;
  searchCodes: (v: string) => void;
  currentTab?: string;
  changeTab: (v: string) => void;
  pagination: PaginationObjT;
  metricsLoading?: boolean;
  isSearching?: boolean;
};
const pageSizeOptions = [10, 25, 50, 100];
export const DashboardPage: React.FC<DashboardPageProps> = ({
  codes,
  goEdit,
  onDelete,
  loading,
  metrics,
  canAddEdit,
  searchCodes,
  pagination,
  metricsLoading,
  isSearching,
  currentTab,
  changeTab,
}) => {
  const brand = getBrandFromCookie();
  const cards: DashboardCardT[] = [
    {
      label: 'Total Code Groups',
      value: String(metrics?.totalGroups || 0),
    },
    {
      label: 'Codes Uploaded',
      value: String(metrics?.codesUploaded),
    },
    {
      label: 'Codes Sent',
      value: String(metrics?.codesSent),
      tooltip: 'The total numbers of codes that clients send guests.',
    },
    // {
    //   label: 'Codes Used',
    //   value: String(metrics?.codesUsed),
    //   tooltip: 'The total numbers of codes that guests check out ticket orders with codes applied',
    // },
  ];
  return (
    <PageWrapper>
      <DashboardHeaderWrapper>
        <Spin spinning={metricsLoading}>
          <Cards cards={cards} />
        </Spin>
        <DashboardActions>
          {canAddEdit && <CreateButton to={route.createCodeGroup.path}>Create New Group</CreateButton>}
        </DashboardActions>
      </DashboardHeaderWrapper>
      <StyledTabs>
        <Tabs activeKey={currentTab} onChange={changeTab}>
          {brand === brands.enc && (
            <>
              <Tabs.TabPane tab="All Groups" key="all">
                <DashboardTable
                  data={codes}
                  goEdit={goEdit}
                  onDelete={onDelete}
                  loading={loading}
                  canAddEdit={canAddEdit}
                  searchCodes={searchCodes}
                  metricsLoading={metricsLoading}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Enchant" key="enchant">
                <DashboardTable
                  data={codes}
                  goEdit={goEdit}
                  onDelete={onDelete}
                  loading={loading}
                  canAddEdit={canAddEdit}
                  searchCodes={searchCodes}
                  metricsLoading={metricsLoading}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Classic" key="coke">
                <DashboardTable
                  data={codes}
                  goEdit={goEdit}
                  onDelete={onDelete}
                  loading={loading}
                  canAddEdit={canAddEdit}
                  searchCodes={searchCodes}
                  metricsLoading={metricsLoading}
                />
              </Tabs.TabPane>
            </>
          )}
          {brand === brands.jingle && (
            <Tabs.TabPane tab="Jingle" key="all">
              <DashboardTable
                data={codes}
                goEdit={goEdit}
                onDelete={onDelete}
                loading={loading}
                canAddEdit={canAddEdit}
                searchCodes={searchCodes}
                metricsLoading={metricsLoading}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </StyledTabs>

      <PaginationWrapper>
        {!isSearching && (
          <Pagination
            showSizeChanger
            onShowSizeChange={pagination.changeLimit}
            current={pagination.pageNumber}
            onChange={pagination.changePage}
            total={pagination.total}
            pageSizeOptions={pageSizeOptions}
          />
        )}
      </PaginationWrapper>
    </PageWrapper>
  );
};

const Cards: React.FC<{cards: DashboardCardT[]}> = ({cards}) => {
  return (
    <DashboardCardsWrapper>
      {cards.map((el, i) => (
        <DashboardCard label={el.label} value={el.value} tooltip={el.tooltip} key={el?.label || i} />
      ))}
    </DashboardCardsWrapper>
  );
};

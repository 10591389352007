import {deleteQuery, getQuery, postQuery, putQuery} from './hooks';
import {CodesQueryList} from '../constants/api';
import {
  addGroupGuestsReq,
  addPromoCodeReq,
  createCodeGroupReq,
  deleteCodeGroupReq,
  DeleteGuestReq,
  deletePromoCodeReq,
  getCodeGroupReq,
  getCodeGroupsReq,
  GetEmailTemplateReq,
  getGroupGuestsReq,
  getListPromoCodesReq,
  getPromoCodeReq,
  SendEmailsGuestReq,
  sendPromoCodeReq,
  ShareBodyT,
  shareCodeGroupReq,
  sharePromoCodeReq,
  updateCodeGroupReq,
  updateGroupGuestsReq,
  updatePromoCodeReq,
} from './types/codes';
import {wrapCreateCodesGroup, wrapUpdateCodesGroup} from '../helpers/queries';

export const getListCodeGroups = async ({pagination, page, limit, brand}: getCodeGroupsReq) =>
  await getQuery(CodesQueryList.getListCodeGroups(pagination, page, limit, brand));

export const createCodeGroup = async ({body}: createCodeGroupReq) =>
  await postQuery(CodesQueryList.createCodeGroup()).send(wrapCreateCodesGroup(body));

export const getCodeGroup = async ({groupId}: getCodeGroupReq) => await getQuery(CodesQueryList.getCodeGroup(groupId));

export const updateCodeGroup = async ({groupId, body}: updateCodeGroupReq) =>
  await wrapUpdateCodesGroup(putQuery(CodesQueryList.updateCodeGroup(groupId)), body);

export const deleteCodeGroup = async ({groupId}: deleteCodeGroupReq) =>
  await deleteQuery(CodesQueryList.deleteCodeGroup(groupId));

export const shareCodeGroup = async ({groupId}: shareCodeGroupReq) =>
  await postQuery(CodesQueryList.shareCodeGroup(groupId));

export const getListPromoCodes = async ({groupId}: getListPromoCodesReq) =>
  await getQuery(CodesQueryList.getListPromoCodes(groupId));

export const addPromoCode = async ({groupId, body}: addPromoCodeReq) =>
  await postQuery(CodesQueryList.addPromoCode(groupId)).field('code', body.code);

export const getPromoCode = async ({groupId, codeId}: getPromoCodeReq) =>
  await getQuery(CodesQueryList.getPromoCode(groupId, codeId));

export const updatePromoCode = async ({groupId, codeId, body}: updatePromoCodeReq) =>
  await putQuery(CodesQueryList.updatePromoCode(groupId, codeId)).send(body);

export const deletePromoCode = async ({groupId, codeId}: deletePromoCodeReq) =>
  await deleteQuery(CodesQueryList.deletePromoCode(groupId, codeId));

export const sendPromoCode = async ({groupId, codeId}: sendPromoCodeReq) =>
  await postQuery(CodesQueryList.sendPromoCode(groupId, codeId));

export const sharePromoCode = async ({groupId, codeId, unshare}: sharePromoCodeReq) =>
  await postQuery(CodesQueryList.shareCode(groupId, codeId, unshare));

export const getPromoCodeMetrics = async () => await getQuery(CodesQueryList.metrics());

export const getSellers = async (brand?: string) => await getQuery(CodesQueryList.getSellers(brand));

export const getCodeGroupGuests = async ({groupId}: getGroupGuestsReq) =>
  await getQuery(CodesQueryList.getCodeGroupGuests(groupId));
export const addCodeGroupGuest = async ({groupId, body}: addGroupGuestsReq) =>
  await postQuery(CodesQueryList.addCodeGroupGuest(groupId)).send(body);
export const updateCodeGroupGuest = async ({groupId, guestId, body}: updateGroupGuestsReq) =>
  await putQuery(CodesQueryList.updateCodeGroupGuest(groupId, guestId)).send(body);
export const deleteGuest = async ({groupId, guestId}: DeleteGuestReq) =>
  await deleteQuery(CodesQueryList.deleteGuest(groupId, guestId));
export const sendGuestEmails = async ({groupId, body}: SendEmailsGuestReq) =>
  await postQuery(CodesQueryList.sendMultipleGuestEmails(groupId)).send(body);

export const resetGroup = async ({groupId}: getGroupGuestsReq) => await deleteQuery(CodesQueryList.resetGroup(groupId));

export const getBrands = async () => await getQuery(CodesQueryList.getBrands());
export const getEventTemplates = async () => await getQuery(CodesQueryList.getEventTemplates());

export const getEmailPreview = async ({groupId, guestId}: GetEmailTemplateReq) =>
  await getQuery(CodesQueryList.getEmailPreview(groupId, guestId));

//share
export const getClientMagicLink = async ({body}: {body: ShareBodyT}) =>
  await postQuery(CodesQueryList.getClientMagicLink()).send(body);

export const getMagicLinkPreview = async (groupId: string) =>
  await postQuery(CodesQueryList.getPreviewMagicLink(groupId));

import {GROUPS_PORTAL_URL, NOTIFICATIONS_PORTAL_URL} from '../constants/env';
import {getToken, getTokenJingle} from './auth';
import {permissionsList} from '../helpers/roles';

export const urlWithToken = (url: string, token: string, expires?: number, token_j?: string) =>
  `${url}?token=${token}&expires=${expires || 604800}&token_j=${token_j || ''}`;

export const getHeaderLinks = (permissions?: permissionsList) => {
  const token = getToken();
  const token_j = getTokenJingle() || '';
  const links = [
    ...(GROUPS_PORTAL_URL && permissions?.ticketAccess
      ? [
          {
            key: 1,
            label: 'Group Tickets Portal',
            href: urlWithToken(GROUPS_PORTAL_URL, token || '', undefined, token_j),
          },
        ]
      : []),
    ...(NOTIFICATIONS_PORTAL_URL && permissions?.isSuperAdmin && permissions?.notificiationsAccess
      ? [
          {
            key: 1,
            label: 'Notifications Portal',
            href: urlWithToken(NOTIFICATIONS_PORTAL_URL, token || '', undefined, token_j),
          },
        ]
      : []),
  ];
  return links;
};

export const removeEmptyKeys = (obj: any) => {
  const result = {};
  Object.keys(obj).forEach((key) => {
    if (!!obj?.[key]) {
      // eslint-disable-next-line
      // @ts-ignore
      result[key] = obj[key];
    }
  });
  return result;
};
